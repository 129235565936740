import { InputHTMLAttributes } from 'react';
import './input.scss';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  placeholder?: string;
  id: string;
  name: string;
  error?: string;
  onChange: (e: any) => void;
}

export function Input({
  type,
  placeholder = '',
  id,
  name,
  error = '',
  ...props
}: TextFieldProps) {
  return (
    <div className="input-wrapper">
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        {...props}
      />
      {error ? <div className="input-wrapper-child">{error}</div> : null}
    </div>
  );
}
