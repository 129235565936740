import { useState } from 'react';

import { clearStorage, getStorageValue, saveToStorage } from '../utils';

export function useAuthProvider() {
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    Boolean(getStorageValue('accessToken'))
  );

  const completeAuth = (accessToken?: string, refreshToken?: string) => {
    saveToStorage('accessToken', accessToken);
    saveToStorage('refreshToken', refreshToken);
    setIsAuthenticated(true);
  };

  const logoutUser = () => {
    clearStorage();
    setIsAuthenticated(false);
  };

  return { isAuthenticated, setIsAuthenticated, completeAuth, logoutUser };
}
