import { Input } from '../common/input';

export function ResetPassword() {
  return (
    <div className="signin-page">
      <div className="signup-page">
        <div className="signup-form-wrapper">
          <div className="singup-form">
            <form>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                onChange={() => {}}
              />
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={() => {}}
              />
              <div className="signup-button-wrapper">
                <button className="button">Complete Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
