// import { useState } from 'react';
import {
  //  Footer,

  Header,
} from '../common';
import './App.scss';
// import { ForgotPassword } from './forgotPassword';
// import { LandingPage } from './landingPage';
// import { Signin } from './signin';
// import { Signup } from './signup';
// import davido from '../../assets/hero/dave-obo.png';
import musizLover from '../../assets/hero/musiz-lovers.png';
import cake from '../../assets/hero/cake.png';
import writer from '../../assets/hero/writers.png';
import dev from '../../assets/hero/dev.png';
// import student from '../../assets/hero/student.png';
import designer from '../../assets/hero/designers.png';
import wedding from '../../assets/hero/wedding.png';
import tailor from '../../assets/hero/tailors.png';
import chef from '../../assets/hero/chef.png';
import handyMan from '../../assets/hero/handy-man.png';
// import burna from '../../assets/hero/Burna-Boy.jpg';
import { useState } from 'react';
// import axios from 'axios';

export function App() {
  // const [show, setShow] = useState('landing');
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  function Submit(e) {
    e.preventDefault();
    // reset error
    setMessage('');

    // set Loading state
    setIsLoading(true);

    setIsSubscriber(false);
    const formDatab = new FormData();
    formDatab.append('Email', email);
    const url: string = process.env.REACT_APP_GOOGLE_API_URL || '';

    if (!email && !url) return;
    fetch(url, {
      method: 'POST',
      body: formDatab,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        console.log(data);
        setMessage('Thank you for subscribing!');
        setIsSubscriber(true);
        setEmail('');
      })
      .catch((error) => {
        console.log(error);
        setMessage('Something went wrong, please try again.');
        setIsSubscriber(false);
        setIsLoading(false);
      });
  }
  return (
    <section className="App">
      <Header />
      <main className="main">
        <section
          className="heroContainer"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="hero_imageContainer item-down">
            <div className="hero_image">
              <img
                src={
                  'https://res.cloudinary.com/dwiloc191/image/upload/v1691215998/teepz/wiz_pyveky.jpg'
                }
                alt=""
              />
            </div>
          </div>
          <div className="hero_imageContainer item-down">
            <div className="hero_image">
              <img src={wedding} alt="Wedding" />
            </div>
            <div className="hero_image">
              <img src={tailor} alt="" />
            </div>
          </div>
          <div className="hero_imageContainer">
            <div className="hero_image">
              <img src={writer} alt="writer" />
            </div>
            <div className="hero_image">
              <img src={dev} alt="Developer" />
            </div>
          </div>
          <div className="hero_imageContainer">
            <div className="hero_image">
              <img
                src={
                  'https://res.cloudinary.com/dwiloc191/image/upload/v1691215951/teepz/Burna-Boy_gi2sci.jpg'
                }
                alt="Davido OBO"
              />
            </div>
            <div className="hero_image">
              <img src={musizLover} alt="Musiz lover" />
            </div>
            <div className="hero_image">
              <img src={cake} alt="Baker: Cakes for party" />
            </div>
          </div>
          <div className="hero_imageContainer">
            <div className="hero_image">
              <img
                src={
                  'https://res.cloudinary.com/dwiloc191/image/upload/v1691217465/teepz/nqobile-vundla-zOt6a59k2BE-unsplash_tupwc0.jpg'
                }
                alt="student"
              />
            </div>
            <div className="hero_image">
              <img src={designer} alt="designer" />
            </div>
          </div>
          <div className="hero_imageContainer item-down">
            <div className="hero_image">
              <img src={chef} alt="chef" />
            </div>
            <div className="hero_image">
              <img src={handyMan} alt="handy man" />
            </div>
          </div>
          <div className="hero_imageContainer item-down">
            <div className="hero_image">
              <img
                src={
                  'https://res.cloudinary.com/dwiloc191/image/upload/v1691216807/teepz/davido_zca87g.jpg'
                }
                alt="bob"
              />
            </div>
          </div>
        </section>
        <section
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '34px',
              maxWidth: '487px',
            }}
          >
            The best place for all your rewards, support and more.
          </h1>
        </section>
        <form onSubmit={Submit}>
          <section
            className="subscribers"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className="subscribers__form"
              style={{
                width: '100%',
              }}
            >
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="false"
                placeholder="Email. example; teepz@example.com"
                style={{
                  padding: '20px 2rem',
                  borderRadius: '60px',
                  fontSize: '16px',
                }}
                value={email}
                required
              />
            </div>
            <button
              // onClick={Submit}
              type="submit"
              className="button-primary"
              style={{
                border: 'none',
                backgroundColor: '#000',
                color: '#fff',
                padding: '1.3rem 2rem',
                borderRadius: '60px',
                fontWeight: 'bold',
                marginTop: '0.8rem',
                cursor: 'pointer',
              }}
              disabled={isLoading}
            >
              {isLoading ? 'LOADING...' : 'GET EARLY ACCESS'}
            </button>
            {message ? (
              <div
                id="message"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: isSubscriber ? '#37a63c' : 'red',
                  color: '#fff',
                  padding: '1.3rem 2rem',
                  borderRadius: '10px',
                  fontWeight: '600',
                  marginTop: '0.8rem',
                }}
              >
                {message}
              </div>
            ) : null}
          </section>
        </form>
        {/* {show === 'landing' ? <LandingPage setShow={setShow} /> : null}
        {show === 'signup' ? <Signup setShow={setShow} /> : null}
        {show === 'signin' ? <Signin setShow={setShow} /> : null}
        {show === 'forgot-password' ? (
          <ForgotPassword setShow={setShow} />
        ) : null} */}
      </main>
      {/* <Footer /> */}
    </section>
  );
}
