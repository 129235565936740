import {
  // OptionsDot,
  Teepz,
} from '../../assets';
import './styles.scss';

export function Header() {
  return (
    <header className="App-header">
      <a href="/" className="App-title">
        <div className="App-logo">
          <Teepz />
        </div>
      </a>
      <div className="App-dotOption">{/* <OptionsDot /> */}</div>
    </header>
  );
}
