import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './components/app';
import { ResetPassword } from './components/app/resetPassword';

function RootRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<App />}>
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RootRoute;
