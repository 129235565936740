// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Component, ErrorInfo, ReactNode } from 'react';
import './styles.scss';

// const searchParams = new URLSearchParams(window.location.search);
// const senderEmail: string = searchParams?.get('senderEmail') || '';

// Bugsnag.start({
//   apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
//   plugins: [new BugsnagPluginReact()],
//   enabledReleaseStages: ['production', 'staging'],
//   releaseStage: process.env.REACT_APP_ENVIRONMENT,
//   user: {
//     email: senderEmail,
//   },
// });

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    // Bugsnag.notify(new Error('Test error'));
    console.log({ error, _errorInfo });
  }

  public render() {
    // Bugsnag.notify(new Error('testing error'));

    if (this.state.hasError) {
      return (
        <div className="Error__boundaryContainer">
          <div className="Error__boundary">
            <h3>Error</h3>
            <p>Oops! We have a problem</p>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
