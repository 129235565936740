import { createContext, Dispatch, ReactNode, SetStateAction } from 'react';
import { useAuthProvider } from '../hooks';

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  completeAuth: (accessToken?: string, refreshToken?: string) => void;
  logoutUser: () => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export function AuthProvider({ children }: { children: ReactNode }) {
  const value = useAuthProvider();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
